<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('administration.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="status">{{ $t('administration.table.fields.status') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          &nbsp;
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="primary"
            @click="clearFailAttr"
          >
            <span class="text-nowrap">{{ $t('general.clear_fail_product_attribute_values') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StatusMixin from '@/mixins/StatusMixin'
import SweetAlertMixin from '@mixins/SweetAlertMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  mixins: [StatusMixin, SweetAlertMixin],
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  methods: {
    clearFailAttr() {
      this.confirm(() => {
        this.$http.post('/api/clear_attributes/')
          .then(() => this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'AlertCircleIcon',
              variant: 'primary',
            },
          }))
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
